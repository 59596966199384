import React from 'react'
import styled from 'styled-components'
import Link from 'components/Link'
import Image from 'components/Image'
import { color, mediaQuery } from 'utils/style'

const Background = styled.div`
  background-color: ${color('color')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 100%;
  /* prettier-ignore */
  /*transform: matrix3d(0.9, -0.1, 0, ,
                    0.1, 0.9, 0, ,
                    0, 0, 1, 0,
                    0, 0, 0, 1);
  transform-origin: 0px 0px 0px;*/
  transform-origin: top left;
  transform: rotateZ(-5deg) scale(1.07) translateX(-6.2%);
  opacity: 0.9;
  z-index: 601;
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  ${mediaQuery()}
  height: ${({ layout }) => (layout === 'column' ? 'fit-content' : '100%')};
  z-index: 600;
`

const ImgWrapper = styled.div`
  position: relative;
  flex: 1;
  margin: ${({ layout }) => (layout === 'column' ? '16px' : '8px 16px')};
  ${({ imageBorders, isEdit }) =>
    imageBorders
      ? `
        & .gatsby-image-wrapper {
          overflow: visible !important;
        }
        & img {
          padding: 16px;
          background: white;
          box-shadow: 0px 0px 10px #00000017;
          max-width: 100%;
          max-height: 100%;
          width: fit-content !important;
          height: fit-content !important;
          ${
            isEdit
              ? ''
              : `top: 50% !important;
                 left: 50% !important;
                 transform: translate(-50%, -50%);
                `
          }
        }`
      : ''}
`

const StyledImage = styled(Image)(
  ({ layout = 'column' }) =>
    ({
      column: {
        position: 'absolute',
      },
      row: {
        position: 'relative',
        minWidth: 18,
        maxHeight: 18,
        marginRight: -16,
      },
    }[layout])
)

const Content = styled.div`
  h5,
  p {
    color: ${color('contrast')};
  }
  position: relative;
  padding: 16px;
  top: 0;
  left: 0;
  z-index: 602;
`

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  ${({ layout }) =>
    ({
      row: {
        justifyContent: 'center',
        '& > *': {
          width: 'auto',
          flex: 'initial',
          alignSelf: 'center',
        },
        '& h5': {
          margin: 0,
          fontSize: 16,
        },
      },
      column: {
        flexDirection: 'column',
      },
    }[layout || 'column'])};
`

export default function StoryblokTileOverlayText({ isEdit, data, dimensions }) {
  return (
    <Link
      link={data.link}
      gtmEvent={data.gtmEvent}
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <Wrapper layout={data.layout}>
        <ImgWrapper
          isEdit={isEdit}
          layout={data.layout}
          imageBorders={data.imageBorders}
        >
          <StyledImage
            src={data.image}
            alt={data.imageAlt}
            title={data.imageTitle}
            description={data.imageDescription}
            layout={data.layout}
          />
        </ImgWrapper>
        {(data.title || data.subtitle) && (
          <Container
            layout={data.layout}
            media={device =>
              dimensions(device)?.w > 8 ? 'width: 61.8%;' : 'width: 100%;'
            }
          >
            <Content color={data.overlayColor}>
              {data.title && <h5>{data.title}</h5>}
              {data.subtitle && <p>{data.subtitle}</p>}
            </Content>
            <Background color={data.overlayColor} />
          </Container>
        )}
      </Wrapper>
    </Link>
  )
}
